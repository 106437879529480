import axios from 'axios';
import FormData from 'form-data';
import { useRouter } from 'next/router';
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { authFormState } from '../../Atoms/auth/AuthFormState';
import { userState } from '../../Atoms/auth/UserAuth';
import { useSession, getSession, signIn } from "next-auth/react";
import Image from 'next/image';
import { API_URL } from '../../config';
import { toast } from 'react-toastify';
// import language from '../../helpers/language';
import { NextPage } from 'next';
import { useTranslation } from 'next-i18next';
import Loader from '../Loader';

interface IFormValues {
    email: string;
    password: string;
}

const LoginCard: NextPage = (props: any) => {

    const { data: session } = useSession();
    const router = useRouter()
    const { t } = useTranslation('common')
    const [userStatea, setUserState] = useRecoilState(userState)
    const setFormState = useSetRecoilState(authFormState)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors } } = useForm<IFormValues>();
    const onSubmit = async (formData: any) => {
        setLoading(true);
        const res = await axios({
            method: 'post',
            url: API_URL + '/login',
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
        }).finally(()=>setLoading(false));
        var data = await res.data;


        if (!data.success) {
            setLoading(false)
            setError(data.message);
            return false;
        }

        if (data.success == true) {
            if (data.trading_challenge) {
                return router.push(data.redirect_after_login);
            }

            axios.post(API_URL + '/get_publisher_id', { "user_id": data.user_id })
                .then((res: any) => {
                    if (res.statusText == "OK" && res.data.success) {
                        let data = res.data.data;
                        localStorage.setItem("publisher-id", JSON.stringify(data))
                    }
                }).catch(e => {
                    console.error(e);
                    throw new Error(e.message);
                }).finally(() => {
                    // data["login_flow_new_user"] = "1"; //after complete code this line will be remove.
                    localStorage.setItem("user-info", JSON.stringify(data));
                    localStorage.setItem("userAuthenticated", "false")
                    router.push({
                        pathname: '/two-factor-auth',
                        query: {
                            redirect: window.location.href,
                            locale: router.query.locale || 'en'
                        }
                    })


                });
        }
    }

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem("user-info")!);
        const userAuthenticated = localStorage.getItem("userAuthenticated");
        if (userInfo?.success == true && userAuthenticated === "true") {
            if (router.query.redirect) {
                router.push(router?.query?.redirect?.toString());
                return () => { };
            }
            if (userInfo?.login_flow_new_user == "1") {
                // router.push('/welcome');
                // return false;
                //router.push(`/on-boarding`);
                router.push(`/explore?locale=${userInfo?.language}`);
            } else {
                router.push(`/build?locale=${userInfo?.language}`);
            }
        }
    }, [session, router])

    if (session) {
        //router.push('/build')
        // return (
        //   <div className="">
        //     Welcome user<br />
        //     <button onClick={() => signOut()}>Sign out</button>
        //   </div>
        // );
    }

    return (
        <>
            <h4 className='text-newGreen text-5xl absolute w-[100%] top-[-75px] dark:text-white dark:opacity-80'>{t('components.loginCard.signIn')}</h4>
            <div className='max-w-[500px]'>
                <form method="post" autoComplete='false' onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-4 loginForm'>
                    <input
                        {...register("email", {
                            required: true, pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: t('components.loginCard.error.validEmail')
                            }
                        })}
                        type="text"
                        onKeyUp={() => setError("")}
                        className='border-newGreen !rounded-full text-newGreen !bg-transparent  dark:bg-transparent dark:border-white dark:text-white'
                        placeholder={t('components.loginCard.emailAddress')}
                        name='email'
                    />
                    {errors?.email &&
                        <div className="danger">
                            {errors.email?.type === "required" && <p className='text-red-500 text-sm'>{t('components.loginCard.error.emailRequired')}</p>}
                            {errors.email?.type === "pattern" && <p className='text-red-500 text-sm'>{errors.email?.message}</p>}
                        </div>
                    }
                    <input
                        {...register("password", { required: true })}
                        type="password"
                        placeholder={t('components.loginCard.password')}
                        onKeyUp={() => setError("")}
                        className='border-newGreen !rounded-full text-newGreen !bg-transparent dark:bg-transparent dark:border-white dark:text-white'
                        name='password'
                    />
                    {errors?.password &&
                        <div className="danger">
                            {errors.password?.type === "required" && <p className='text-red-500 text-sm'>{t('components.loginCard.error.passwordRequired')}</p>}
                        </div>
                    }
                    {error && <span className='text-red-500 text-sm'>{error}</span>}

                    <div className='text-center'>

                        {loading
                            ?
                            <Loader />
                            :
                            <button
                                type='submit'
                                className='bg-gradient-to-r from-[#FD009A] to-[#FFCE00] border-2 border-[#FFCE00] shadow-[0_0px_10px_-1px_rgba(255,206,0,1)] text-white px-4 py-1 rounded-full'
                            >{t('components.loginCard.signIn')}</button>}
                    </div>

                </form>
            </div>
            <div className="flex flex-col !mt-2 dark:text-white">
                <div className='flex flex-row justify-center items-center text-newGreen dark:text-white text-xs'>
                    <div className='w-[30%] border-t border-newGreen dark:border-white'></div>
                    <p className='mx-2'>{t('components.loginCard.or')}</p>
                    <div className='w-[30%] border-t border-newGreen dark:border-white'></div>
                </div>
                <p className='text-white'>{t('components.loginCard.loginWith')}</p>
                <div className='flex flex-row justify-center space-x-10 my-2'>
                    <button onClick={() => signIn('google')}><i className='fa fa-google text-2xl text-white ' /></button>
                    {/* <button onClick={() => signIn()}><Image src="/images/linkedin.svg" height={40} width={40} alt="linkedin" /></button> */}
                </div>
            </div>
            <div className='space-y-4 dark:text-white'>
                <div className='text-center text-xs text-white'>

                    <div>
                        {t('components.loginCard.resetPassword')}
                        <span
                            className='text-newGreen cursor-pointer select-none'
                            onClick={() => setFormState({ view: 'resetPassword' })}
                        > {t('components.loginCard.here')}</span>
                    </div>
                    <div>
                        {t('components.loginCard.riskTrading')}
                        <span
                            className='text-newGreen cursor-pointer select-none'
                            onClick={() => router.push('https://www.mktdynamics.com/disclaimer')}
                        > {t('components.loginCard.notice')}</span>
                    </div>
                    <div>
                        {t("Do not have account? Create ")}
                        <span
                            className='text-newGreen cursor-pointer select-none'
                            onClick={() => router.push({
                                pathname: 'https://mktdynamics.com/getstarted',
                                query: {
                                    redirect: router.query.redirect,
                                    locale: router.query.locale || 'en'
                                }
                            })}
                        > {"here"}</span>
                    </div>
                </div>
                <hr className='border-newGreen dark:border-white' />
                <div className='text-center text-xs text-white'>
                    <span>{t('components.troubleLogging')}</span>
                    <span>{t('components.contactMail')}</span>
                </div>

            </div>

        </>
    )
}

// LoginCard.getInitialProps(context) {
//     // Fetch data from external API
//     // const res = await fetch(`https://.../data`)
//     // const data = await res.json()

//     // Pass data to the page via props
//     return { props: { } }
// }

export default LoginCard;