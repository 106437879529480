import axios from 'axios';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { authFormState } from '../../Atoms/auth/AuthFormState';
import { useForm } from "react-hook-form";
import { API_URL } from '../../config';
import { useTranslation } from 'next-i18next';

interface IFormValues {
    email: string;
    otp:number;
    new_password:string;
}

type ResetPasswordProps = {
    toast?:any
};

const ResetPassword: React.FC<ResetPasswordProps> = (props:ResetPasswordProps) => {
    // router declearation
    const router = useRouter()
    const { t } = useTranslation('common')
    // global state manipulation
    const setFormState = useSetRecoilState(authFormState);
    const [showChangePasswordFields,setShowChangePasswordFields] = useState(false);
    const [loading,setLoading] = useState(false);

    const { register, handleSubmit, getValues, formState: { errors } } = useForm<IFormValues>();

    // handling the from submit button
    const onSubmit = async (formData:any) => {
        // const res = await axios({
        //     method: 'post',
        //     url: API_URL+'/send_email_otp',//'/forgot_password',
        //     data: formdata,
        //     headers: { 'Content-Type': 'multipart/form-data' },
        // })
        const res =  await axios.post(API_URL + '/send_email_otp', {
            email: formData.email
        });
        const data = await res.data
        if(data.success==true){
            setShowChangePasswordFields(true);
        }
        props.toast(data.success,data.message);
        //data.message
    }

    const onSubmitOTP = async (formData:any) => {
        const res =  await axios.post(API_URL + '/change_password', formData);
        const data = await res.data
        if(data.success==true){
            setFormState({ view: 'login' });
        }
        props.toast(data.success,data.message);
        //data.message
    }

    const resendOTP = async() => {
        if(loading==true){
            console.log("Loading...");
            return false;
        }
        setLoading(true);
        const res =  await axios.post(API_URL + '/send_email_otp', {
            email: getValues("email")
        });
        const data = await res.data;
        setLoading(false);
        props.toast(data.success,data.message);
    }

    return (
        <>
            <h4 className=' text-newGreen text-3xl absolute w-[100%] top-[-68px] dark:text-white'>{t('components.resetPassword.forgotPassword')}</h4>
            <div className='max-w-[500px]'>
                {showChangePasswordFields==false &&
                <form action="" method="post" onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-4 resetForm'>
                    <div className='text-left'>
                            <p className='text-sm mb-5 text-white dark:text-white'>{t('components.resetPassword.emailAddressText')}</p>
                        <input
                            {...register("email",{required:true, pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: t('components.resetPassword.validEmail') 
                            }})}
                            type="email"
                                placeholder={t('components.resetPassword.emailAddress')}
                            name='email'
                            className='!rounded-full !bg-transparent text-newGreen dark:text-white'
                            autoComplete='off'
                        />
                        { errors?.email &&
                            <div className='danger'>
                                <div className="text-red-500 text-center mt-3 text-sm">
                                        {errors.email?.type === "required" && <p>{t('components.resetPassword.emailRequired')}</p> }
                                    { errors.email?.type === "pattern" && <p>{errors.email?.message}</p> }
                                </div>
                            </div>
                        }
                    </div>
                    <div className='text-center'>
                        <button
                            type='submit'
                            className='bg-gradient-to-r from-[#FD009A] to-[#FFCE00] border-2 border-[#FFCE00] shadow-[0_0px_10px_-1px_rgba(255,206,0,1)] text-white px-4 py-1 rounded-full'
                            >{t('components.resetPassword.submit')}</button>
                    </div>
                </form>}
                {showChangePasswordFields==true &&
                <form action="" method="post" onSubmit={handleSubmit(onSubmitOTP)} className='flex flex-col space-y-4 resetForm'>
                    <div className='text-left'>
                            <p className='text-sm mb-5 flex flex-row justify-between text-white dark:text-white'>{t('components.resetPassword.otp')} <span className='text-sm text-newGreen cursor-pointer' onClick={() => { resendOTP() }}>{t('components.resetPassword.resendOtp')}</span></p>
                        <input
                            {...register("otp",{required:true})}
                            type="text"
                            placeholder={t('components.resetPassword.otpPlaceholder')}
                            name='otp'
                            className='!rounded-full !bg-transparent text-newGreen dark:text-white'
                            autoComplete='off'
                        />
                        { errors?.otp &&
                            <div className='danger'>
                                <div className="text-red-500 text-center mt-3 text-sm">
                                        {errors.otp?.type === "required" && <p>{t('components.resetPassword.otpRequired')}</p> }
                                    { errors.otp?.type === "pattern" && <p>{errors.otp?.message}</p> }
                                </div>
                            </div>
                        }
                    </div>
                    <div className='text-left'>
                            <p className='text-sm mb-5 text-white dark:text-white'>{t('components.resetPassword.enterNewPassword')}</p>
                        <input
                            {...register("new_password",{required:true, minLength:6})}
                            type="text"
                            placeholder={t('components.resetPassword.newPassword')}
                            name='new_password'
                            className='!rounded-full !bg-transparent text-newGreen dark:text-white'
                            autoComplete='off'
                        />
                        { errors?.new_password &&
                            <div className='danger'>
                                <div className="text-red-500 text-center mt-3 text-sm">
                                        {errors.new_password?.type === "required" && <p>{t('components.resetPassword.newPasswordRequired')}</p> }
                                        {errors.new_password?.type === "minLength" && <p>{t('components.resetPassword.passwordError')}</p> }
                                </div>
                            </div>
                        }
                    </div>
                    <div className='text-center'>
                        <button
                            type='submit'
                            className='bg-gradient-to-r from-[#FD009A] to-[#FFCE00] border-2 border-[#FFCE00] shadow-[0_0px_10px_-1px_rgba(255,206,0,1)] text-white px-4 py-1 rounded-full'
                            >{t('components.resetPassword.submit')}</button>
                    </div>
                </form>}
            </div>
            <div className='space-y-4'>
                <div className='text-center text-xs mt-5 text-white'>
                    <div>
                        <span className='dark:text-white dark:opacity-80'>{t('components.resetPassword.loginClick')}</span>
                        <span
                            className='text-newGreen cursor-pointer select-none'
                            onClick={() => setFormState({ view: 'login' })}
                        > {t('components.resetPassword.here')}</span>
                    </div>
                    <div>
                        <span className='dark:text-white dark:opacity-80'>{t('components.resetPassword.click')}</span>
                        <span
                            className='text-newGreen cursor-pointer select-none'
                            onClick={() => router.push('https://mktdynamics.com/')}
                        > {t('components.resetPassword.here')} </span>
                        <span className='dark:text-white dark:opacity-80'>{t('components.resetPassword.backToWebsite')}</span>
                    </div>

                </div>
                <hr className='border-newGreen dark:border-white' />
                <div className='text-center text-xs text-white'>

                    <span>{t('components.troubleLogging')}</span>
                    <span>{t('components.contactMail')}</span>
                </div>

            </div>
        </>
    )
}
export default ResetPassword;